import React from 'react'
import Header from './../components/layout/Header'
import Footer from './../components/layout/Footer'
import FlowMenu from './../components/FlowMenu';
import { useContext } from 'react';
import MainContext from './../context/MainContext';
import ModalContext from './../context/ModalContext';
import api from './../services/api'
import { useEffect } from 'react';
import LoadingModal from './../components/modals/LoadingModal';
import ValidateOfflineFlow from './../components/modals/ValidateOfflineFlow';
import NotificationModal from './../components/notification/Notification';
import SyncAnswerNotificationModal from './../components/notification/SyncAnswerNotification';
import ReloadFlowModal from './../components/modals/ReloadFlowModal';
import AnswerNotResponseNotificationModal from './../components/notification/AnswerNotResponse';
import AnswerNotFileNotificationModal from './../components/notification/AnswerNotFile';
import LoginInfo from '../components/layout/LoginInfo';
import { useLocation } from 'react-router-dom';

// Get query params
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Flow () {
  const {
    setEquipamentArray,
    setFlowArray,
    flowOnline,
    setFlowOnline,
    downloadedFlow,
    // selectedEquipament,
    // selectedFlow
  } = useContext(MainContext);
  
  const {
    setLoadingModalOpen,
    setValidateModalOpen,
    downloadFlowModalOpen,
    setDownloadFlowModalOpen,
    syncAnswerModalOpen,
    setSyncAnswerModalOpen
  } = useContext(ModalContext);

  const queryParams = useQuery();
  const type = queryParams.get('type');

  useEffect(() => {
    const offlineFlows = JSON.parse(localStorage.getItem(`otimize-flows-${ type }`));

    if (offlineFlows) {
      setValidateModalOpen(true)
      return
    }

    setFlowOnline(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Boolean(flowOnline)) {
      setLoadingModalOpen(true)

      api
        .get(`/machines`)
        .then(res => {
          console.log(res)
          setEquipamentArray(res.data)
          setLoadingModalOpen(false)
        });

      api
        .get(`/maintenance-flows/${ type }`)
        .then(res => {
          setFlowArray(res.data.filter(fluxo => {
            fluxo.perguntas = fluxo.perguntas.filter(pergunta => pergunta.status == true);

            if (fluxo.perguntas.length === 0) {
              return false;
            }

            return fluxo;
          }));

          setLoadingModalOpen(false);
        });

      setFlowOnline(null)
      return
    }

    if (!Boolean(flowOnline)) {
      const offlineFlows = JSON.parse(localStorage.getItem(`otimize-flows-${ type }`));

      const equipamentos = [];
      const fluxos = [];

      if (!offlineFlows) {
        return
      }

      offlineFlows.forEach(el => {
        equipamentos.push(el.equipamento);
        
        const fluxosId = fluxos.map(fluxo => fluxo.id);

        el.flows.forEach(flow => {
          if (!fluxosId.includes(flow.id)) {
            fluxos.push(flow)
          }
        });
      });

      setEquipamentArray(equipamentos);
      setFlowArray(fluxos);
      setFlowOnline(null)
      return
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowOnline])

  useEffect(() => {
    if (downloadedFlow) {
      setDownloadFlowModalOpen(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadedFlow])

  useEffect(() => {
    if (downloadFlowModalOpen) {
      setTimeout(() => setDownloadFlowModalOpen(false), 2500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadFlowModalOpen])

  useEffect(() => {
    if (syncAnswerModalOpen) {
      setTimeout(() => setSyncAnswerModalOpen(false), 2500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncAnswerModalOpen])
    
  return (
    <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
      <NotificationModal />
      <SyncAnswerNotificationModal />
      <AnswerNotResponseNotificationModal />
      <AnswerNotFileNotificationModal />
      <ValidateOfflineFlow />
      <ReloadFlowModal />
      <LoadingModal />
      <Header />
      <LoginInfo />
      <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
        <FlowMenu type={ type } />
      </div>
      <Footer />
    </section>
  )
}